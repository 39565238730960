.projectPageContainer {
    text-align: start;
    padding-top: 100px;
    width: max(55%, 550px);
    padding-bottom: 50px;
}
.projectPageTitle {
    margin-top: 25px;
}

.projectPageIntro {
    font-size: 1.5em;
    font-family: TitilliumWeb-Regular, sans-serif;
    margin-top: 35px;
    color: #cecece;
    text-align: center;
    padding: 15px 30px;
    border-radius: 10px;
    background-color: #1f1f1f;
    width: 100%;
}

.projectPageImg {
    border-radius: 25px;
    width: 300px;
    /*margin-bottom: 35px;*/
    /*height: 16vw;*/
}

.projectPageLogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.projectPageContainer h1 {
    color: white;
    font-family: RobotoSlab, sans-serif;
    font-weight: 800;
    font-size: 2.5em;
}
.projectPageContainer h2 {
    margin-top: 45px;
    color: white;
    font-family: RobotoSlab, sans-serif;
    font-weight: 550;
    font-size: 1.6em;
}
.projectPageContainer h3 {
    margin-top: 45px;
    color: white;
    font-family: RobotoSlab, sans-serif;
    font-weight: 550;
    font-size: 1em;
}
.projectPageDesc, .projectPageTitleContainer div, .projectPageSkills {
    font-family: TitilliumWeb-Regular, sans-serif;
    margin-top: 30px;
    /*text-align: justify;*/
    /*hyphens: auto;*/
    /*-webkit-hyphens: auto;*/
    /*word-spacing: -0.05em;*/
}

.projectPageDesc, .projectPageSkills {
    color: whitesmoke;
}

.projectPageTitle {
    margin-bottom: 25px;
}
.projectPageDesc {
    margin-bottom: 30px;
}

.screenshots {
    width: calc(100svw - (55%/2));
    margin-left: calc(-55% / 2);
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    grid-column: 1/-1;
    grid-gap: 10px;
    /*grid-auto-columns: max-content;*/
    /*grid-auto-rows: 100px;*/
}
.screenshot {
    cursor: pointer;
    width: auto;
    height: 20vw;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    border-radius: 7px;
    filter: brightness(100%);
    object-fit: cover;
    user-select: none;
    transition: 0.4s;

}
.screenshot:hover {
    filter: brightness(70%);
    transition: 0.2s;
}
.screenshot:active {
    transition: 0.4s;
}

.projectSwitcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 1.2em;
    background-color: #1a1a1a;
    padding: 15px 15px;
    border-radius: 10px;
    margin: 0px -40px 35px -40px;
    text-align: center;
}
.switcherTitle {
    font-family: RobotoSlab, sans-serif;
    font-size: 2.75em;
    font-weight: 700;
    /*transform: translateX(calc(-5%));*/
}

.projectButton {
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
}

.projectSwitcherInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}
.projectSwitcherInner:hover {
    filter: brightness(50%);
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s;
}


.openScreenshot {
    margin-bottom: 15px;
    max-height: 85dvh;
    /*width: 100%;*/
    max-width: 85vw;
    height: auto;
    border-radius: 10px;
}