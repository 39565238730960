@media screen and (max-device-width: 640px) {
    /*.iconContainer, .bubbleFill {*/
    /*    height: 5.5vmin;*/
    /*    width: 5.5vmin;*/
    /*    padding: 8px;*/
    /*}*/
    .iconContainer:hover {
        cursor: pointer;
        transition: 0.5s;
        background: #4c4c4c;
    }
}