.homeBubbleText {
    z-index: -1;
    font-size: 1.85vmin;
    font-family: RobotoSlab, sans-serif;
    margin-top: 0vmin;
    color: white;
    transition: 0.3s;
}
.homeBubbleTexttrue {
    transition: 0.3s;
    margin-top: 13vmin;
}

.iconContainer, .bubbleFill, .icon {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

.iconContainer, .bubbleFill {
    height: 10vmin;
    width: 10vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #2e2e2e;
    border-radius: 50%;
    transition: 0.5s;
}
.iconContainer:hover, .bubbleFill:hover {
    cursor: pointer;
    transition: 0.5s;
    background: #4c4c4c;
}
.icon {
    z-index: 2;
    filter: invert(74%) sepia(2%) saturate(13%) hue-rotate(6deg) brightness(95%) contrast(79%);
    position: fixed;
    width: 5.5vmin;
    transition: 0.5s;
}
.icon1 {
    opacity: 1;
    animation: spin1 30s infinite linear reverse;
}
.icon2 {
    animation: spin2 30s infinite linear reverse;
}
.icon3 {
    animation: spin3 30s infinite linear reverse;
}
.icon4 {
    animation: spin4 30s infinite linear reverse;
}
.icon:hover {
    filter: invert(94%) sepia(0%) saturate(0%) hue-rotate(141deg) brightness(97%) contrast(90%);
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.2s;
}

.outerSpinningSquare {
    opacity: 0;
    transform: scale(0.75);
}
.outerSpinningSquare, .spinningSquare {
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.spinningSquare {
    animation: spin 30s infinite linear;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spin1 {
    0% {
        transform: translate(-15vmin, -15vmin) rotate(0deg);
    }
    100% {
        transform: translate(-15vmin, -15vmin) rotate(360deg);
    }
}
@keyframes spin2 {
    0% {
        transform: translate(15vmin, -15vmin) rotate(0deg);
    }
    100% {
        transform: translate(15vmin, -15vmin) rotate(360deg);
    }
}
@keyframes spin3 {
    0% {
        transform: translate(15vmin, 15vmin) rotate(0deg);
    }
    100% {
        transform: translate(15vmin, 15vmin) rotate(360deg);
    }
}
@keyframes spin4 {
    0% {
        transform: translate(-15vmin, 15vmin) rotate(0deg);
    }
    100% {
        transform: translate(-15vmin, 15vmin) rotate(360deg);
    }
}