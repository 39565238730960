@media screen and (max-device-width: 640px) {
    .gqlPoster {
        width: 100%;
        flex: 1;
        border-radius: 7px;
        object-fit: cover;
        margin-top: 0px;
        text-align: center;
    }

    .innerPosterDiv {
        width: 100%;
    }

    .mobileImgNoPad {
        width: 100%;
    }

    .mobileImg {
        width: calc(100% - 30px);
    }
}
