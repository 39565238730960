@media only screen and (max-width: 640px) {
    .projectSlide, .projectContainer, .carousel {
        overflow: hidden;
        height: 100svh;
        overscroll-behavior: none;
    }
    .projectSlidetrue, .projectContainertrue, .carouseltrue {
        height: calc(95svh - 60px) !important;
    }

    .carousel {
        top: 0px;
    }

    .dotContainer {
        gap: 10px;
        width: fit-content;
    }
    .dotContainertrue {
        flex-direction: column;
        bottom: calc(50svh - 57.5px);
        left: auto;
        right: 2%;
    }
}

