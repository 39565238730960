.projectContent, .projectContenttrue {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transform: scale(1);
    cursor: pointer;
    padding: 35px 0 35px 0;
    border-radius: 10px;
    max-width: 85%;
}
.projectContenttrue {
    padding: 0 0 0 0 !important;
}
.projectContent:hover {
    background-color: #1a1a1a;
    transform: translateX(0px) scale(1.05);
    transition: 0.3s;
}

.projectImg {
    height: 20vw;
    border-radius: 25px;
}
.projectText {
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    width: 55%;
}
.projectTitle {
    font-family: RobotoSlab, sans-serif;
    font-size: 5vw;
    font-weight: 700;
}
.projectDesc {
    font-size: 1.5vw;
    margin-top: 20px;
    font-family: TitilliumWeb-Regular, sans-serif;
}