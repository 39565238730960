@media screen and (max-device-width: 640px) {
    .aboutPhotoContainer {
        width: 100%;
    }

    .timelineContainer h2 {
        margin-bottom: 75px;
        font-size: 2em;
    }

    .aboutProfile {
        width: 80%;
        height: auto;
        margin-bottom: 45px;
        margin-left: 0px;
        max-width: 400px;
    }

    .meContainer {
        margin-top: 100px;
        flex-direction: column-reverse;
    }

    .thatsMe {
        width: 60%;
        margin-top: -100px;
        margin-left: 0px;
        max-width: 300px;
        margin-right: 0px;
    }

    .aboutDesc {
        width: 80%;
        margin-top: 30px;
        margin-bottom: 70px;
    }

    .checkOut {
        margin-bottom: 0;
    }

    .skillBox {
        padding: 25px 25px;
    }

    .skillBoxContainer {
        gap: 15px;
        margin-bottom: 15px;
    }

    .parchment {
        width: 100%;
        /*height: auto;*/
        border-radius: 10px;
    }
}
