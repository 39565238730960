@media screen and (max-device-width: 640px) {
    .locPhotoContainer {
        width: 100%;
    }

    .imgLocContainer {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .locImg {
        margin-bottom: 50px;
        max-width: 400px;
    }
}
