.leftCircleContainer, .rightCircleContainer {
    display: flex;
    align-items: center;
    padding: 20px;
}

.leftCircleContainer {
    margin-left: -300px;
    flex-direction: row-reverse;
}
.rightCircleContainer {
    margin-left: 300px;
    flex-direction: row;
}

.circle {
    border-radius: 50%;
    border: 2px solid #ffffff;
    height: 80px;
    width: 80px;
    z-index: 2;
    margin: 25px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(50%);
    animation: showCircle 0.5s;
}
@keyframes showCircle {
    0% {
        height: 0px;
        width: 0px;
    }
    100% {
        height: 80px;
        width: 80px;
    }
}
.expandCircle {
    height: 50px;
    width: 50px;
    animation: none !important;
}
@keyframes showExpandCircle {
    0% {
        height: 50px;
        width: 50px;
    }
    100% {
        height: 50px;
        width: 50px;
    }
}
.circle:hover {
    height: 90px;
    width: 90px;
    transition: 0.3s;
}

.leftCircleText, .rightCircleText {
    width: 300px;
    color: white;
    z-index: 1;
    animation: showText 0.5s;
}
@keyframes showText {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.leftCircleText {
    text-align: right;
}

.circleTitle {
    font-family: RobotoSlab, sans-serif;
    font-weight: 750;
    font-size: 1.25em;
    pointer-events: none;
    margin-top: 10px;
}
.circleCompany {
    color: #c0c0c0;
    font-family: RobotoSlab, sans-serif;
    font-weight: 550;
    font-size: 1.0em;
    pointer-events: none;
    margin-bottom: 10px;
}
.circleDesc {
    height: 0;
    font-family: TitilliumWeb-Regular, sans-serif;
    font-size: 0.9em;
    /*margin-top: -110px;*/
    opacity: 0%;
    pointer-events: none;
}
.timeRange {
    font-family: TitilliumWeb-Regular, sans-serif;
    color: #d9d9d9;
    font-size: 0.85em;
    margin-top: 10px;
}