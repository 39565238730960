.demoContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.unity {
    width: 100vw;
    height: 100dvh;
}

.loadingOverlay {
    font-family: TitilliumWeb-Regular, sans-serif;
    font-size: 6vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    color: white;
}