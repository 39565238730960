.imgLocContainer {
    /*margin-top: 100px;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 25%;
    z-index: 0;
}

.locPhotoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.locationDiv {
    z-index: 0;
    opacity: 0%;
    margin-top: -75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events : none;
}
.locationPin {
    margin-right: 10px;
    width: 20px;
    filter: invert(11%) sepia(78%) saturate(6848%) hue-rotate(359deg) brightness(50%) contrast(110%);
}
.locationName {
    font-family: TitilliumWeb-Regular, sans-serif;
    color: whitesmoke;
    font-size: 1.65vw;
}

.locImg {
    width: 100%;
    border-radius: 20px;
    filter: grayscale(100%);
    transition: 0.5s;
    cursor: pointer;
    z-index: 1;
}

.locImg:hover {
    filter: grayscale(0%);
    transition: 0.5s;
}