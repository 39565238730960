.contactContainer {
    height: 100dvh;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 20;
    align-items: center;
    justify-content: center;
    pointer-events: visiblePainted;
    cursor: pointer;
}

.contactDialogBoxContainer {
    display: flex;
    width: auto;
    height: 85%;
    padding: 35px;
    background-color: #262626;
    border-radius: 10px;
    color: white;
    font-family: TitilliumWeb-Regular, sans-serif;
    flex-direction: column;
    font-size: 2vw;
    z-index: 5;
    cursor: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.closeDialogBtn {
    font-weight: bold;
    border-radius: 4px;
    color: white;
    border: none;
    background-color: #6e6e6e;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 25px;
    right: 25px;
    font-size: 1.25em;
    height: 40px;
    width: 40px;
    text-align: center;
    cursor: pointer;
    transition: 0.2s;
}
.closeDialogBtn:hover {
    background-color: #838383;
    transition: 0.2s;
}