.allProjectDisplayContainer {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*display: flex;*/
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
}
.projectDisplayContainer:nth-child(2n + 1) { /* Select the first column */
    justify-self: end; /* Align to the right */
}

.projectDisplayContainer:nth-child(2n) { /* Select the third column */
    justify-self: start; /* Align to the left */
}

.projectDisplayContainer {
    width: 40%;
    height: auto;
    border-radius: 25px;
    background-color: #2e2e2e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    padding: 30px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    transition: background-color 0.2s, opacity 1s, scale 1s, all 0.5s;
    scroll-margin-top: 125px;
}
.projectDisplayContainer img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: 15px;
}
.projectDisplayContainer h1 {
    text-align: center;
    font-size: 1.25em;
}

.projectDisplayContainer:hover {
    background-color: #373737;
    cursor: pointer;
    transition: background-color 0.2s, opacity 1s, scale 0.2s, all 0.5s;
    scale: 1.05 !important;
}