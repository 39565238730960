@font-face {
    font-family: "RobotoSlab";
    src: url("RobotoSlab-VariableFont_wght.ttf") format("woff2 supports variations"),
        url("RobotoSlab-VariableFont_wght.ttf") format("woff2-variations");
    font-weight: 100 900;
}

@font-face {
    font-family: "TitilliumWeb-Regular";
    src: url("TitilliumWeb-Regular.ttf") format("woff2 supports variations"),
        url("TitilliumWeb-Regular.ttf") format("woff2-variations");
    font-weight: normal;
}

@font-face {
    font-family: "TitilliumWeb-Italic";
    src: url("TitilliumWeb-Italic.ttf") format("woff2 supports variations"),
    url("TitilliumWeb-Italic.ttf") format("woff2-variations");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "TitilliumWeb-Bold";
    src: url("TitilliumWeb-Bold.ttf") format("woff2 supports variations"),
    url("TitilliumWeb-Bold.ttf") format("woff2-variations");
    font-weight: bold;
}

@font-face {
    font-family: "TitilliumWeb-SemiBold";
    src: url("TitilliumWeb-SemiBold.ttf") format("woff2 supports variations"),
    url("TitilliumWeb-SemiBold.ttf") format("woff2-variations");
    font-weight: auto;
}

@font-face {
    font-family: "TitilliumWeb-Light";
    src: url("TitilliumWeb-Light.ttf") format("woff2 supports variations"),
    url("TitilliumWeb-Light.ttf") format("woff2-variations");
    font-weight: auto;
}