.homeInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 2;
    width: 85%;
    gap: 25px;
    /*height: 100vh;*/
    /*height: calc(var(--vh, 1vh) * 100);*/
    justify-content: center;
}

.infoText1 {
    color: white;
    font-size: 5vmin;
    font-family: RobotoSlab, sans-serif;
    font-weight: 750;
    transition: 0.2s;
}
.infoTexttrue {
    opacity: 0 !important;
    font-size: 3vmin;
    margin-top: -80px !important;
    transition: 0.3s;
}

.infoText2 {
    color: white;
    font-size: 2vmin;
    font-family: RobotoSlab, sans-serif;
    font-weight: 300;
    transition: 0.2s;
}

.emptySquare {
    position: absolute;
    width: 35vmin;
    height: 35vmin;
}
.homeImage {
    position: sticky;
    opacity: 0%;
    filter: grayscale(100%);
    cursor: pointer;
    z-index: 10;
    transform: scale(1.0);
    width: calc(35vmin - (35vmin - 10px) * var(--scroll-position, 0));
    border-radius: calc(20px + (50% - 20px) * var(--scroll-position, 0));
    top: calc((35vmin / 2) - ((35vmin / 2) - 27px) * var(--scroll-position, 0));
    transition: transform 0.5s, border-radius 0.5s, filter 0.5s;
    /*transition: transform 0.2s, margin-top 0.3s, border-radius 0.1s, border 0.3s;*/
    /*animation: pulse 5s 2s ease-in-out infinite;*/
}
@keyframes pulse {
    0% {
        transform: scale(1);
        transition: 0.5s;
    }
    4% {
        transform: scale(1.025);
        transition: 0.5s;
    }
    8% {
        transform: scale(1);
        transition: 0.5s;
    }
    12% {
        transform: scale(1.025);
        transition: 0.5s;
    }
    16% {
        transform: scale(1);
        transition: 0.5s;
    }
    100% {
        transform: scale(1);
        transition: 0.5s;
    }
}

@media (hover: hover) {
    .homeImageHoverfalse:hover {
        transform: scale(1.05) !important;
        filter: grayscale(50%);
        transition: transform 0.5s, border-radius 0.5s, filter 0.5s;
    }
    .homeImageHovertrue:hover {
        transform: scale(0.85) !important;
        transition: transform 0.5s, border-radius 0.5s, filter 0.5s;
        filter: grayscale(0%);
    }
}

.homeImageHoverfalse:active, .homeImageHovertrue:active {
    filter: grayscale(0%);
    transition: transform 0.5s, border-radius 0.5s, filter 0.5s;
}
.homeImagetrue {
    border-radius: 50%;
    margin-top: 0;
    transform: scale(calc(0.8 + ((0.2) * var(--scroll-position, 0)))) !important;
    /*width: calc(25vmin - (25vmin - 11px) * var(--scroll-position, 0)) !important;*/
    border: calc(5px - (5px * var(--scroll-position, 0))) solid gray;
    transition: transform 0.5s, border-radius 0.5s, filter 0.5s;
    filter: grayscale(0%);
}

