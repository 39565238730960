@media screen and (max-device-width: 640px) {
    .contactDialogBoxContainer {
        /*margin-top: -75px;*/
        height: fit-content;
        width: 70%;
        background-color: rgba(0, 0, 0, 0);
        font-size: 1.5em;
    }

    .closeDialogBtn {
        top: 25px;
        right: 25px;
        font-size: 1.75em;
        height: 35px;
        width: 35px;
    }
}