.timelineContainerfalse, .timelineContainertrue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
    height: auto;
    /*overflow: hidden;*/
    align-items: center;
}
.timelineContainerfalse {
    height: auto;
    transition: height 0.5s ease;
}
.timelineContainertrue {
    transition: height 0.5s ease;
    height: max-content;
}

.timelineContainer h2 {
    margin-bottom: 40px;
    font-size: 3em;
}

.circleContainer:last-child {
    margin-bottom: 75px;
}

.gapLine {
    background-color: #939393;
    z-index: 0;
    height: 125px;
    width: 4px;
    pointer-events: none;
    margin-top: -50px;
    margin-bottom: -50px;
}