@media only screen and (max-width: 1024px) {
    ::selection {
        color: rgba(0, 0, 0, 0);
        background: none;
    }
    ::-moz-selection {
        color: rgba(0, 0, 0, 0);
        background: none;
    }

    html, body {
        overflow: hidden;
    }

    .innerPageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .bodyText {
        font-size: min(4.5vw, 18px);
    }
}
