.navbarBubble {
    z-index: 1;
    width: 75px;
    height: 75px;
    position: fixed;
    border-radius: 0 0 60px 60px;
    background-color: #333333;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-15px) scale(0.25);
    mask-image: linear-gradient(to bottom, transparent 50%, black 50%);
    opacity: 0;
}

.navbarBubbletrue {
    opacity: 1;
    transform: translateY(-30px) scale(6);
    transition: 0.3s;
}

.circle-container {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.circle-image {
    filter: invert(74%) sepia(2%) saturate(13%) hue-rotate(6deg) brightness(95%) contrast(79%);
    position: absolute;
    width: 8px;
    height: 8px;
    aspect-ratio: 1 / 1;
    /*object-fit: cover;*/
    transition: 0.3s;
    cursor: pointer;
    transform: scale(1.0);
}
.circle-image:hover {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(79deg) brightness(103%) contrast(103%);
    transform: scale(1.1);
    transition: 0.3s;
}