.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 75px;
    overflow: overlay;
}

.meContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    width: 100%;
}

.aboutPhotoContainer {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.aboutDesc {
    margin-top: 75px;
    margin-bottom: 75px;
    width: max(55%, 550px);
    font-family: TitilliumWeb-Regular, sans-serif;
    color: whitesmoke;
    /*text-align: justify;*/
    /*word-break: keep-all;*/
    /*display:inline;!*added*!*/
    /*hyphens: auto;*/
    /*-webkit-hyphens: auto;*/
    /*word-spacing: -0.05em;*/
}

.aboutProfile {
    width: 100%;
    border-radius: 20px;
    filter: grayscale(100%);
    transition: 0.5s;
    cursor: pointer;
    z-index: 3;
}

.aboutProfile:hover {
    filter: grayscale(0%);
    transition: 0.5s;
}

.thatsMe {
    width: 35%;
    filter: invert(100%);
    margin-top: 45px;
    margin-right: -75px;
    z-index: 1;
    pointer-events : none;
}

.however {
    color: gray;
}

.checkOut {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
}
.aboutProjectLink {
    color: cornflowerblue;
    transition: border-bottom-width 0.1s linear;
    cursor: pointer;
    border-bottom: 0px solid cornflowerblue;
}
.aboutProjectLink:hover {
    border-bottom: 2px solid cornflowerblue;
    filter: brightness(75%);
    transition: border-bottom-width 0.1s linear;
}

.skillBoxContainer {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 25px;
}
.skillBox {
    text-align: center;
    padding: 30px 40px;
    border-radius: 15px;
    background-color: #1a1a1a;
    flex: 1 1 fit-content;
    filter: brightness(100%);
    transition: 0.3s;
}
.skillBox::before {
    transform: rotate(30deg);
}
.skillBox:hover {
    transform: scale(1.1);
    filter: brightness(125%);
    /*cursor: pointer;*/
    transition: 0.3s;
}

.parchment {
    width: auto;
    height: inherit;
    border-radius: 10px;
}