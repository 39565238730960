.navbar {
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: #333333;
    height: 60px;
    position: fixed;
    top: 15px;
    border-radius: 10px;

    /*mask: url('../../img/navbar/logo.svg') center/contain no-repeat, linear-gradient(#000 0 0);*/
    /*mask-size: 55px;*/
    /*mask-composite: exclude;*/
}

.navbarContainer div {
    color: white;
}

.navbarContent {
    height: 70%;
}

.navbarLinks {
    display: flex;
    flex-direction: row;
    width: 450px;
    justify-content: start;
    margin-left: 45px;
    gap: 75px;
    font-size: 25px;
    font-family: TitilliumWeb-Regular, sans-serif;
    transition: 0.2s;
    border-bottom: 0px solid whitesmoke;
    z-index: 2;
    transform: scale(1);
}

.navIcon {
    width: auto;
    height: 100%;
}
.link {
    filter: invert(74%) sepia(2%) saturate(13%) hue-rotate(6deg) brightness(95%) contrast(79%);
    cursor: pointer;
    transition: 0.2s;
}
.link:hover {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(79deg) brightness(103%) contrast(103%);
    transition: 0.2s;
    transform: scale(1.15);
}

.navbarContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
}

.logoDiv {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
}

.logo {
    z-index: 3;
    height: 35px;
    transform: rotate(0deg);
    transition: filter 0.4s, transform 0s;
}
@media (hover: hover) {
    .logo:hover {
        filter: invert(50%);
        transition: 0.4s;
        cursor: pointer;
    }
}
.logo:active {
    filter: invert(50%);
    transition: 0.4s;
    cursor: pointer;
}


.socialLink {
    z-index: 10;
    height: 30px;
    filter: invert(74%) sepia(2%) saturate(13%) hue-rotate(6deg) brightness(95%) contrast(79%);
    transition: 0.3s;
}

.socialLinks {
    margin: 0 20px 0 0;
    gap: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: pointer;
}
.linkedIn:hover {
    transition: 0.2s;
    transform: scale(1.1);
    filter: invert(28%) sepia(97%) saturate(1165%) hue-rotate(176deg) brightness(96%) contrast(98%);
}
.mail:hover {
    transition: 0.2s;
    transform: scale(1.1);
    filter: invert(68%) sepia(50%) saturate(7354%) hue-rotate(78deg) brightness(112%) contrast(111%);
}


.navbarTitle {
    width: calc(50% - 60px);
    height: fit-content !important;
    opacity: 0.5;
    position: absolute;
    font-family: RobotoSlab, sans-serif;
    left: 20px;
    font-size: 2rem;
    text-align: start;
}
.navbarTitle div {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}