.floatingBackTopBtn {
    position: fixed;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #212121;
    bottom: 25px;
    z-index: 5;
    left: calc(50% - 25px);
    text-align: center;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: 0.5s;
    margin-top: 10px;
    line-height: 50px;
}

.sentencesSS {
    width: 20vw;
    padding: 15px;
    background-clip: border-box;
    background-color: white;
    border-radius: 15px;
}

.sectionImg {
    width: 25vw;
}

/* Poster section */
.gqlPoster {
    width: 35vw;
    flex: 1;
    border-radius: 7px;
    object-fit: cover;
    text-align: center;
}

.posterDiv {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.innerPosterDiv {
    width: 35vw;
}

.highlightPoster {
    position: absolute;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
    /*background-color: red;*/
}      
.highlightPoster:hover {
    background-color: rgba(0, 89, 255, 0.29);
    transition: 0.2s;
}
.section1 {
    height: 0.8%;
    width: 13.85vw;
    margin-left: 0.65vw;
    margin-top: 19px;
}
.section2 {
    height: 16svh;
    width: 18.75svw;
    margin-left: 15.6svw;
    margin-top: 17.5svh;
}
.section3 {
    height: 15svh;
    width: 13.85svw;
    margin-left: 0.65svw;
    margin-top: 30.2svh;
}
.section4 {
    height: 22.5svh;
    width: 18.75svw;
    margin-left: 15.6svw;
    margin-top: 34.5svh;
}
.section5 {
    height: 23.5svh;
    width: 13.85svw;
    margin-left: 0.65svw;
    margin-top: 52.75svh;
}
.section6 {
    height: 18.5svh;
    width: 18.75svw;
    margin-left: 15.6svw;
    margin-top: 57.85svh;
}
.sectionQ {
    height: 5.4svh;
    width: 13.85svw;
    margin-left: 0.65svw;
    margin-top: 46.3svh;
}