@media screen and (max-device-width: 640px) {
    .homeInfoContainer {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .infoText1 {
        font-size: min(10vw, 65px);
        text-align: center;
    }
    .infoTexttrue {
        font-size: min(3vw, 65px) !important;
        transform: translateY(-100px) !important;
        margin-top: -65px !important;
    }

    .infoText2 {
        font-size: min(5vw, 16px);
    }

    .infoText3, .developerText, .artistText, .amongText{
        font-size: min(7vw, 50px);
        text-align: center;
    }

    .homeImage {
        width: calc(70vw - ((70vw - 8px) * var(--scroll-position, 0))) !important;
        top: calc((70vw / 2) - ((70vw / 2) - 28px) * var(--scroll-position, 0));
        left: calc(0 + ((50% - 6px) * var(--scroll-position, 0)));
    }
    .homeImagetrue {
        margin-top: 0 !important;
    }

    .homeImagetrue, .homeImagetrue:active, .homeImagetrue:hover {
        margin-top: 0 !important;
        transform: scale(calc(0.4 + (0.6 * var(--scroll-position, 0)))) !important;
        /*transform: scale(0.4) !important;*/
        /*width: calc(35vw - ((35vw - 10px) * var(--scroll-position, 0))) !important;*/
        border: calc(3px - (3px * var(--scroll-position, 0))) solid gray
    }
    .homeImagetrue:active {
        width: calc(35vw - ((35vw - 10px) * var(--scroll-position, 0))) !important;
    }
}
