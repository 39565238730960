.App {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #141414;
}
html {
    background-color: #141414;
}

.bodyText {
    font-size: 1.25rem;
}

h1 {
    font-family: RobotoSlab, sans-serif;
    color: white;
    font-size: 3em;
}
h2 {
    font-family: RobotoSlab, sans-serif;
    color: white;
    font-size: 2em;
}

.pageContainer {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.innerPageContainer {
    /*mask: linear-gradient(to top, rgba(0,0,0, 1) 0, rgba(0,0,0, 1) 85%, rgba(0,0,0, 0) 100%, rgba(0,0,0, 0) 0) 100% 50% / 100% 100% repeat-x;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /*top: 100px;*/
    height: 100dvh;
    position: relative;
    /*padding-bottom: 75px;*/
    overflow-y: auto;
}
.contentPageContainer {

}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    -webkit-tap-highlight-color: transparent;
}

a:link {
    color: #a1c6d2;
    transition: 0.2s;
}
a:visited {
    color: #70a2ff;
    transition: 0.2s;
}
a:hover {
    color: #9adeff;
    transition: 0.2s;
}
a:active {
    color: #2c2c96;
    transition: 0.2s;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #181818;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}