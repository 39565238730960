@media screen and (max-device-width: 640px) {
    .circle {
        height: 50px;
        width: 50px;
        margin: 15px;
        animation: showCircle 0.5s;
    }
    @keyframes showCircle {
        0% {
            height: 0px;
            width: 0px;
        }
        100% {
            height: 50px;
            width: 50px;
        }
    }
    .expandCircle {
        height: 35px;
        width: 35px;
        animation: showExpandCircle 0.5s;
    }
    @keyframes showExpandCircle {
        0% {
            height: 0px;
            width: 0px;
        }
        100% {
            height: 35px;
            width: 35px;
        }
    }
    .circle:hover {
        height: 60px;
        width: 60px;
    }

    .circleTitle {
        font-size: 3.7vw;
    }
    .circleCompany {
        font-size: 3.5vw;
    }
    .circleDesc {
        font-size: 3.3vw;
    }

    .leftCircleText, .rightCircleText {
        width: 130px;
        color: white;
    }

    .leftCircleContainer, .rightCircleContainer {
         padding: 0px;
     }
    .leftCircleContainer {
         margin-left: -130px;
         flex-direction: row-reverse;
     }
    .rightCircleContainer {
        margin-left: 130px;
        flex-direction: row;
    }

    .gapLine {
        background-color: #939393;
        z-index: 0;
        height: 150px;
        width: 4px;
    }

    .timeRange {
        font-size: 3.2vw;
    }
}
