@media screen and (max-device-width: 1400px) and (min-device-width: 640px) {
    .projectContent {
        flex-direction: column;
    }

    .projectImg {
        height: auto;
        width: 40%;
        margin-bottom: 25px;
    }

    .projectText {
        margin-left: 0px;
        text-align: center;
        width: 75%;
    }

    .projectTitle {
        font-size: 6vw;
    }
    .projectDesc {
        font-size: 2.5vw;
        margin-top: 10px;
    }
}
