.scrollingContainer {
    display: inline-block;
}

.scrollingWelcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    height: calc(100dvh - 90px);
    width: 100%;
}