@media screen and (max-device-width: 640px) {
    .projectPageContainer {
        width: 80%;
        padding-top: 85px;
    }

    .projectPageLogoContainer {
        flex-direction: column;
        margin-bottom: 35px;
        height: auto;
    }

    .projectPageTitleContainer {
        margin-left: 0;
    }
    .projectPageTitleContainer h1 {
        margin: 35px 0 20px 0;
    }

    .projectPageContainer h1 {
        font-size: 2em;
    }
    .projectPageContainer h2 {
         font-size: 1.7em;
     }

    .projectPageImg {
        width: 100%;
        height: auto;
    }

    .projectPageIntro {
        font-size: 20px;
        width: 90%;
    }

    .projectPageDesc, .projectPageSkills {
        width: 100%;
        margin-bottom: 35px;
    }

    .screenshots {
        margin-left: 0;
        width: 100%;
    }

    .screenshot {
        width: 45%;
        height: auto;
    }

    .projectSwitcher {
        padding: 10px 10px;
        font-size: min(4.5vw, 16px);
        margin: 0px -20px 35px -20px;
        /*width: calc(110% - 20px);*/
        /*transform: translateX(-5%);*/
        height: auto;
        transition: 0.3s;
    }
    .switcherTitle {
        font-size: 6vw;
        padding: 0px 20px;
        transform: translateX(5px);
    }
    .projectButton {
        margin: 5px;
    }
}
