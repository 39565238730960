@media screen and (max-device-width: 640px) {
    .mediaContainer {
        width: 85%;
        padding-bottom: 50px;
    }
    .mediaAlbumTitle {
        margin-top: -35px !important;
    }
    .mediaContainer h2 {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .mediaPosterTitle, .mediaLogoTitle {
        margin-top: 75px;
    }

    .mediaCarousel {
        padding-bottom: 15px;
    }

    .mediaCarouselContainer, .mediaPosterCarouselContainer {
        height: 100%;
        width: 100%;
    }
    .mediaPosterCarouselContainer {
        /*height: 475px;*/
    }

    .mediaImg {
        width: 100%;
        transition: 0.2s;
    }

    .mediaDotContainer {
        bottom: -25px;
    }

    .mediaCarouselGrid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        flex-grow: 2;
    }
    .gridItem {
        text-align: center;
        border-radius: 15px;
        /*justify-content: flex-start;*/
        /*flex: 2 2 fit-content;*/
    }
    .mediaImg {
        width: 40vw;
    }
    .logoImg, .logoImgTransparent {
        width: 75px;
    }

    .dialogImg {
        width: 100%;
        /*height: auto;*/
        border-radius: 10px;
    }
}
