.projectSlide, .projectSlidetrue {
    color: white;
    height: 100svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.projectSlidetrue {
    height: calc(70svh - 100px);
}

.projectContainer, .projectContainertrue {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    width: 100%;
    height: 100svh;
    bottom: 75px;
}
.projectContainertrue {
    width: calc(100vw - 10px);
    margin-left: calc(50% - 50vw);
    height: calc(70svh - 100px) !important;
}

.carousel, .carouseltrue {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    overflow: hidden;
}
.carouseltrue {
    height: calc(70svh - 100px) !important;
}
/*.projectContainer, .carousel, .projectSlide {*/
/*     !*max-height: 100%;*!*/
/*     !*width: auto; !*100vw*!*!*/
/*}*/

.arrow {
    height: 15px;
    margin-left: 1px;
}
.arrowBack {
    margin-left: -4px;
    transform: rotateY(180deg);
}
.buttonBack___1mlaL, .buttonNext___2mOCa {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    transition: 0.2s;
}
.buttonBack___1mlaL:hover, .buttonNext___2mOCa:hover {
    filter: brightness(50%);
    transform: scale(1.2);
}
.buttonBack___1mlaL {
    left: 20px;
}
.buttonNext___2mOCa {
    right: 20px;
}
.slideInner___2mfX9 {
    height: 100vh !important;
}

.dotContainer {
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translate(-50%,0);
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dot___3c3SI {
    border: none;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    transition: 0.2s;
    padding: 0px;
}
.carousel__dot--selected {
    transform: scale(1.4);
    transition: 0.2s;
    padding: 0px;
}
.dot___3c3SI:hover {
    filter: brightness(50%);
    transform: scale(1.2);
    padding: 0px;
}
.carousel__dot--0 {
    left: 40%;
    padding: 0px;
}
.carousel__dot--2 {
    left: 60%;
    padding: 0px;
}