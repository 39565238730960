@media screen and (max-device-width: 640px) {
    .projectContent, .projectContenttrue {
        flex-direction: column;
    }

    .projectImg {
        height: auto;
        width: 70%;
        margin-bottom: 25px;
    }

    .projectText {
        margin-left: 0px;
        text-align: center;
        width: 75%;
    }

    .projectTitle {
        font-size: 11vw;
    }
    .projectDesc {
        font-size: 4.55vw;
        margin-top: 10px;
    }
}
