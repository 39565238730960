@media only screen and (max-width: 1024px) {
    .navbar {
        height: 55px;
    }

    .logo {
        height: 30px;
    }

    .navbarLinks {
        margin-left: 15px;
    }

    .mail, .linkedIn {
        height: 25px !important;
    }
    .socialLinks {
        gap: 15px;
        margin: 0 15px 0 0;
    }

    .navbarTitle {
        opacity: 0.5;
        position: absolute;
        left: 15px;
        font-size: 1.25rem;
    }
}