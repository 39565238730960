.mediaDesc {
    margin-bottom: 25px;
    font-family: TitilliumWeb-Regular, sans-serif;
    color: whitesmoke;
    /*text-align: start;*/
    /*hyphens: auto;*/
    /*-webkit-hyphens: auto;*/
    /*word-spacing: -0.05em;*/
}

.mediaContainer {
    display: flex;
    z-index: 0;
    margin-top: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max(55%, 500px);
    padding-bottom: 50px;
}
.mediaContainer h2 {
    width: 100%;
    text-align: start;
}

.mediaAlbumTitle {
    margin-top: 50px;
}

.mediaTitle h2 {
    margin-top: 75px;
    margin-bottom: 25px;
}

.mediaCarouselGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    /*flex-basis:  100%;*/
    /*align-items: start;*/
}
.gridItem {
    /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);*/
    /*background-color: #2e2e2e;*/
    /*padding: 10px;*/
    text-align: center;
    border-radius: 15px;
    /*flex: 1 1 fit-content;*/
    filter: brightness(100%);
    transition: 0.3s;
    display: flex;
    align-items: center;
    /*height: 175px;*/
    /*flex-grow: 1;*/
}
.mediaImg {
    border-radius: 10px;
    /*width: calc(33.33% - 60px);*/
    width: 16vw;
    /*height: 180px;*/
    /*object-fit: cover;*/
    transition: 0.2s;
}
.mediaImg:hover {
    transform: scale(1.025);
    filter: brightness(75%);
    cursor: pointer;
    transition: 0.2s;
}
.mediaImgNoBrightness:hover {
    filter: invert(75%);
}

.mediaCarouselContainer, .mediaPosterCarouselContainer {
    height: 600px;
    width: 600px;
}
.mediaPosterCarouselContainer {
    height: 700px;
}

.mediaSlide {
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    max-height: 100vh;
    width: auto; /*100vw*/
    align-items: flex-start;
    /*padding: 0 25px 0 25px;*/
}

.mediaCarousel {
    position: relative;
}

.albumSlide {
    transition: 0.2s;
}
.albumSlide:hover {
    transform: scale(1.01) translateY(5px);
    filter: brightness(75%);
    cursor: pointer;
    transition: 0.2s;
}

.logoImgTransparent {
    filter: invert(100%);
}
.logoImg, .logoImgTransparent {
    width: 100px;
}

.mediaDotContainer {
    position: relative;
    left: 50%;
    /*bottom: 50px;*/
    transform:translate(-50%,0);
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.mediaArrowContainer {
    position: relative;
    bottom: 53.5%;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.mediaArrowButton {
    position: relative;
}
.mediaBackArrowButton {
    left: 10px;
}
.mediaNextArrowButton {
    right: 10px;
}

.dialogImg {
    width: auto;
    /*width: auto;*/
    /*width: auto;*/
    /*height: calc(100vh - 50px);*/
    height: inherit;
    border-radius: 10px;
    cursor: pointer;
}

.mediaTopImg {
    opacity: 0;
}

/*.mediaGrid {*/
/*    gap: 0;*/
/*    justify-content: center;*/
/*    column-gap: 20px;*/
/*    display: grid !important;*/
/*    grid-template-columns: repeat(auto-fill, 175px);*/
/*    grid-auto-rows: 25px;*/
/*}*/